<template>
  <div>
    <!--  null  -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("core/auth", ["logout"]),
    logOut() {
      this.logout();
      location.reload();
    }
  },
  mounted() {
    this.logOut();
  }
};
</script>
